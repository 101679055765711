<template>
  <div>
    <div class="upper flex">
      <div class="bloсks graphic">
        <!-- TradeChart :tradeChart="tradeChart" /-->
        <!-- TODO: Просто тестовые s и 'ff' чтобы не забыть -->
        <TradeView :ticker="{s: 'ff'}" />
      </div>
      <div class="bloсks b-right">
        <Dropdown
          v-model='pairsSelected'
          id='BookList'
          :options='pairsView'
          option-label='value'
          placeholder='Trade Books'
          :disabled = 'pairsDisabled'
          @change='GetBalance'
        />
        <TradePairs
          :orderPrice="orderPrice"
          :orderBooksSale="orderBooksSale"
          :orderBooksBuy="orderBooksBuy"
          @update-orderbook='GetOrderBook'
        />
      </div>
      <div class="bloсks b-right">
        <TradeList :tradeList="tradeList" />
      </div>
    </div>
    <div class="down flex">
      <div class="bloсks b-left">
        <TradeHistory
          :tradeHistory='tradeHistory'
          :tradeOrders='tradeOrders'
          :tasks-list='tasksList'
          @delete-order='deleteOrder'
        />
      </div>
      <div class="bloсks b-left">
        <TabView class="text-xs" ref="tabview">
          <TabPanel header="Limit">
            <TradeLimit :tradeMarket="tradeMarket" @update-orders='updateAll'/>
          </TabPanel>
          <TabPanel header="Market">
            <TradeMarket />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Trading',
  data() {
    return {
      // Need api
      tradePairs: [],
      pairsView: [],
      pairsSelected: {},
      pairsDisabled: true,
      orderPrice: {
        lastPrice: 0,
        currentPrice: 0,
        token: '',
        tokenQuote: '',
      },
      orderBooksSale: [],
      orderBooksBuy: [],
      tradeList: [],
      tradeHistory: [],
      tradeOrders: [],
      tasksList: [],
      tradeMarket: {
        limitHave: {
          moneyHave: 0,
          valueHave: 0,
          tokenBlocked: 0,
          tokenQuoteBlocked: 0,
        },
        limitBuy: {
          priceLimitBuy: '',
          valueLimitBuy: '',
        },
        limitSale: {
          priceLimitSale: '',
          valueLimitSale: '',
        },
        mnemonic: {
          token: '',
          tokenQuote: '',
        },
        id: {
          token: 0,
          tokenQuote: 0,
        },
      },
      tradeChart: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
        ],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: '#2f4860',
            data: [65, 59, 80, 81, 56, 55, 40],
          },
          {
            label: 'My Second dataset',
            backgroundColor: '#00bb7e',
            data: [28, 48, 40, 19, 86, 27, 90],
          },
        ],
      },
    }
  },
  mounted() {
    this.getTradePairs();
    this.$socket.send('[5,"order/channel"]');
    this.$socket.send('[5,"candle/channel"]');
    this.$socket.send("[8,\"candle/channel\", \"{'type':'candles','target': 1,'timeframe':'m1'}\"]");
    this.$options.sockets.onmessage = (data) =>{
      const strAnswear = String(data.data);
      if (strAnswear.length === 0) return;
      const i = strAnswear.indexOf('msg');
      if (i === -1) return;
      const eventMsg = JSON.parse(strAnswear.slice(i + 5, -2));
      let delta = 0;
      switch (eventMsg.event)
      {
        case 'orderbook_updated': this.GetOrderBook(); break;
        case 'order_canceled': this.deleteOrder(eventMsg.id);
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: `Order ${eventMsg.id} was deleted`,
            group: 'info',
            life: 3000,
          });
          break;
        case 'candle_updated':
          if (this.getCallback === null || this.getLastBar === null) break;
          delta = eventMsg.candle.s - this.getLastBar.time;
          if (delta > 0)
          {
            const bar = {};
            bar.time = eventMsg.candle.s;
            bar.low = eventMsg.candle.l;
            bar.high = eventMsg.candle.h;
            bar.open = eventMsg.candle.o;
            bar.close = eventMsg.candle.c;
            bar.volume = eventMsg.candle.v;
            if (delta > 60) this.$store.commit('setLastBar', bar);
            this.getCallback(bar);
          }
          break;
      }
    }
  },

  unmounted() {
    delete this.$options.sockets.onmessage;
  },

  methods: {
    async getTradePairs() {
      try
      {
        const resp = await this.axios.get('/api/pairs')
        this.tradePairs = resp.data
        const tradePairsAll = resp.data
        for (const item of tradePairsAll) {
          if (item.isEnabled === true) {
            this.pairsView.push({"id": item.id, "value": item.id + '-' + item.token.mnemonic + '/' + item.tokenQuote.mnemonic});
          }
        }
        for (const item of tradePairsAll){
          if (item.isEnabled === false)
          {
            this.pairsView.push({"id": item.id, "value": item.id + '-' + item.token.mnemonic + '/' + item.tokenQuote.mnemonic});
          }
        }
        if (this.pairsView.length !== 0) {
          this.pairsDisabled = false;
          this.pairsSelected = this.pairsView[0];
          await this.GetBalance();
        }
      }
      catch (e)
      {
        console.log('error', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: '/api/pairs get error',
          group: 'info',
          life: 3000,
        })
      }
    },

    async GetBalance()
    {
      if (this.tradePairs.length === 0) return;
      const token = this.tradePairs[this.pairsSelected.id - 1].token.id;
      const tQuote = this.tradePairs[this.pairsSelected.id - 1].tokenQuote.id;

      try
      {
        const resp = await this.axios.get('/api/au_owners');
        const allOwner = resp.data;
        this.tradeMarket.mnemonic.token = this.tradePairs[this.pairsSelected.id - 1].token.mnemonic;
        this.tradeMarket.mnemonic.tokenQuote = this.tradePairs[this.pairsSelected.id - 1].tokenQuote.mnemonic;
        this.tradeMarket.id.token = token;
        this.tradeMarket.id.tokenQuote = tQuote;
        this.orderPrice.token = this.tradeMarket.mnemonic.token;
        this.orderPrice.tokenQuote = this.tradeMarket.mnemonic.tokenQuote;
        let tokenFound = false;
        let tokenQuoteFound = false;

        for (const item of allOwner)
        {
          if (item.auToken.id === token)
          {
            this.tradeMarket.limitHave.valueHave = item.amount;
            this.tradeMarket.limitHave.tokenBlocked = item.amountBlocked;
            tokenFound = true;
          }
          if (item.auToken.id === tQuote)
          {
            this.tradeMarket.limitHave.moneyHave = item.amount;
            this.tradeMarket.limitHave.tokenQuoteBlocked = item.amountBlocked;
            tokenQuoteFound = true;
          }
          if (tokenFound && tokenQuoteFound) break;
        }

        if (!tokenFound)
        {
          this.tradeMarket.limitHave.valueHave = 0;
          this.tradeMarket.limitHave.tokenBlocked = 0;
        }
        if (!tokenQuoteFound)
        {
          this.tradeMarket.limitHave.moneyHave = 0;
          this.tradeMarket.limitHave.tokenQuoteBlocked = 0;
        }
      }
      catch (e)
      {
        console.log('error', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'api/au_owners get error',
          group: 'info',
          life: 3000,
        })
      }
      await this.GetOrders();
      await this.GetTradeList();
      await this.GetOrderBook();
    },

    async GetOrders(){
      if (this.tradePairs.length === 0) return;
      const token = '/api/au_tokens/' + this.tradePairs[this.pairsSelected.id - 1].token.id;
      const tQuote = '/api/au_tokens/' + this.tradePairs[this.pairsSelected.id - 1].tokenQuote.id;
      this.tradeOrders.length = 0;
      this.tradeHistory.length = 0;

      try
      {
        const resp = await this.axios.get('/api/au_orders');
        const ordersAll = resp.data;
        for (const item of ordersAll)
        {
          if (item.token === token && item.tokenQuote === tQuote)
          {
            item.amountProcent = item.amountExecuted / (item.amount / 100);
            item.amountProcent += ' %';
            item.displayTime = moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss');

            if (item.isFinish === false)
            {
              if (item.isCancelRequest === true) item.deleteProgress = 50;
              this.tradeOrders.push(item);
              if (item.isTask === true) this.tasksList.push(item);
            }
            else this.tradeHistory.push(item);
          }
        }
      }
      catch (e)
      {
        console.log('error', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: '/api/au_orders/ get error',
          group: 'info',
          life: 3000,
        })
      }
    },

    async GetTradeList() {
      if (this.tradePairs.length === 0) return;
      this.tradeList.length = 0;
      try
      {
        const resp = await this.axios.get(`/api/trades?pairId=${this.pairsSelected.id}`);
        const tradeListAll = resp.data;
        for (const item of tradeListAll)
        {
          item.displayTime = moment(item.createdAt).format('hh:mm:ss');
          this.tradeList.push(item);
        }

        if (this.tradeList.length !== 0)
        {
          this.orderPrice.currentPrice = Number(this.tradeList[this.tradeList.length - 1].price);
          if (this.tradeList.length > 1)
          {
            this.orderPrice.lastPrice = Number(this.tradeList[this.tradeList.length - 2].price);
          }
        }
      }
      catch (e)
      {
        console.log('error', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: '/api/trades get error',
          group: 'info',
          life: 3000,
        })
      }
    },

    async GetOrderBook()
    {
      if (this.tradePairs.length === 0) return;
      this.orderBooksSale.length = 0;
      this.orderBooksBuy.length = 0;
      try
      {
        const resp = await this.axios.get(
          `api/pairs/order-book/${this.pairsSelected.id}?count=100`);
        this.orderBooksSale = resp.data.sell;
        this.orderBooksSale = this.orderBooksSale.reverse();
        this.orderBooksBuy = resp.data.buy;
      }
      catch (e)
      {
        console.log('error', e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: '/api/trades get error',
          group: 'info',
          life: 3000,
        });
      }
    },

    async updateAll()
    {
      await this.GetOrders();
      await this.GetTradeList();
      await  this.GetOrderBook();
    },

    deleteOrder(id)
    {
      for (let i = 0; i < this.tradeOrders.length; ++i)
      {
        if (this.tradeOrders[i].id === id)
        {
          this.tradeOrders[i].deleteProgress = 50;
          this.tradeOrders[i].isCancelRequest = true;
        }
      }
    },
  },
  computed: {
    getLastBar() {
      return this.$store.state.lastBar;
    },
    getCallback () {
      return this.$store.state.callback;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/layout.scss';

.bloсks {
  box-sizing: border-box;
  background: white;
  border-left: 2px solid var(--surface-ground);
  border-right: 2px solid var(--surface-ground);
  border-bottom: 2px solid var(--surface-ground);
  border-radius: $borderRadius;
  padding: 5px;
}
.graphic {
  width: 100%;
}
.b-right {
  width: 200px;
}
.b-left {
  width: 50%;
}
.upper {
  flex-wrap: nowrap;
}
.down {
  flex-wrap: nowrap;
}

@media (max-width: 991px) {
  .upper {
    flex-wrap: wrap;
  }
  .graphic {
    width: 100%;
  }
  .b-right {
    width: 50%;
  }
}
@media (max-width: 550px) {
  .down {
    flex-wrap: wrap;
  }
  .b-right {
    width: 100%;
  }
  .b-left {
    width: 100%;
  }
}
.container{
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
}
.LabelText{
  flex-basis: 50%;
  font-style: italic;
  font-size: smaller;
}
</style>
